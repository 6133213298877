.copyright {
  text-align: center;
}

.footer {
  font-size: 15px !important;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
}