.profileIcon {
  display: inline-block;
  font-size: 28px;

  color: #011f3d;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  &:hover {
    color: #1890ff;
  }
}

.userName {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 240px;
}

$textColor: #74708d;

.dropdownContainer {
  position: relative;
  line-height: normal;
  display: flex;
  align-items: center;
  text-align: left;
  padding-right: 1rem;
  text-transform: uppercase;
  font-size: 1rem;
  color: $textColor;
  cursor: pointer;

  &:after {
    color: $textColor;
    opacity: 0.8;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -2px;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
}