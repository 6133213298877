@import 'components/mixins.scss';

.balanceIcon {
  margin-right: $space-xs;
}

.balanceBlock {
  display: flex;
  align-items: center;
  max-width: 110px;
  overflow: hidden;

  @media (max-width: $sm-max-width) {
    font-size: 1.3rem;
  }
}