// Import mixins
@import 'components/mixins.scss';

.ant-table-cell {
  &::before {
    display: none !important;
  }
}

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-table-column-has-sorters {
    &:hover {
      background: $dark-gray-5 !important;
    }
  }
  .ant-table-column-sort {
    background: $dark-gray-5 !important;
  }
}
