.valueBox {
  display: inline-flex;
  align-items: center;
  max-width: 100%;
}

.value {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}

.valueSign {
  margin-right: 0.3rem;
}