.logo {
  justify-content: center;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 800;
  border-bottom: 1px solid;
  border-color: #f0f2f5;
  display: flex;
  flex-direction: row;
  height: 64px;
  transition: opacity 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  opacity: 1;

  &__collapsed {
    flex: 0 0 80px;
    max-width: 80px;
    min-width: 80px;
    width: 80px;
  }

  &__hidden {
    opacity: 0;
  }
}