.handler {
  position: absolute;
  top: 72px;
  width: 41px;
  height: 40px;
  cursor: pointer;
  z-index: 0;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;

  right: -40px;
  -webkit-box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
  border-radius: 0 4px 4px 0;
}

.drawerHandleIcon {
  width: 14px;
  height: 2px;

  position: relative;
  -webkit-transition: background 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: background 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  background: #001529;

  &:before {
    top: -5px;
  }
  &:after {
    top: 5px;
  }

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background: #001529;
    transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }


  &__open {
    background: transparent;
    &:before {
      transform: translateY(5px) rotate(45deg);
    }
    &:after {
      transform: translateY(-5px) rotate(-45deg);
    }
  }
}