@import 'src/components/mixins.scss';

/* ICON */
.icon {
  text-align: center;
  position: absolute;
  right: 14px;
  width: 20px;
  top: 13px;
  color: $secondary;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

/////////////////////////////////////////////////////////////////////////////////////////
/* MENU COMPONENT */
.menu {
  background: $white !important;
  color: $text;
  transition: none !important;
  font-size: rem(15);
  min-height: 100%;


  /////////////////////////////////////////////////////////////////////////////////////////
  /* COLLAPSE TRIGGER BUTTON */
  :global(.ant-layout-sider-trigger) {
    background: $light !important;
    color: $text !important;
    transition: none !important;
  }

  /////////////////////////////////////////////////////////////////////////////////////////
  /* ANTD MENU CUSTOMIZATION */
  .navigation {
    padding: 0 14px !important;
    transition: none !important;
    background: transparent;
    border-right: none !important;
    color: $text;
    overflow-y: auto !important;

    .title {
      font-size: 14px;
    }

    :global(.ant-menu-submenu > .ant-menu) {
      background: transparent;
    }

    :global(.ant-menu-submenu-arrow) {
      opacity: 0.55;
    }

    :global(.ant-menu-inline .ant-menu-item),
    :global(.ant-menu-inline .ant-menu-submenu-title) {
      width: 100%;
    }

    :global(.ant-menu-submenu-inline) > :global(.ant-menu-submenu-title .ant-menu-submenu-arrow) {
      right: 18px;
    }

    :global(.ant-menu-item-group-title) {
      color: $gray-4 !important;
      font-size: rem(12);
      text-transform: uppercase;
      letter-spacing: 2px;
    }

    :global(.ant-menu-submenu-title) {
      margin-bottom: 0 !important;
      transition: none !important;
      background: transparent !important;
      color: $text;

      &:hover {
        color: $primary;
      }
    }

    :global(.ant-menu-submenu) {
      border-radius: 5px;
      will-change: transform;
    }

    :global(.ant-menu-item) {
      margin-bottom: 4px !important;
      border-radius: 5px;
      width: 100% !important;
      transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
      > span {
        color: $text;
      }

      > a {
        color: $text;

        &:hover {
          color: $primary;

          .icon {
            color: $primary;
          }
        }
      }
    }

    :global(.ant-menu-submenu-active) {
      .icon {
        color: $primary;
      }
    }

    :global(.ant-menu-submenu-active),
    :global(.ant-menu-item-active) {
      background: $gray-2 !important;
    }

    :global(.ant-menu-item-selected) {
      background: $primary !important;

      & a {
        color: $white !important;

        .icon {
          color: $white !important;
        }
      }

      &:after {
        display: none;
      }
    }

    :global(.ant-menu-item-active) {
      > span {
        color: $primary;
      }
    }

    :global(.ant-menu-item-disabled) {
      > span {
        color: $gray-4;
      }
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////
  /* COLLAPSED STYLES */
  &:global(.ant-layout-sider-collapsed) {
    @at-root {
      :global(.ant-menu-inline-collapsed-tooltip) .icon {
        display: none;
      }
    }

    :global(.badge) {
      position: absolute;
      z-index: 1;
      top: 3px;
      right: 3px;
    }

    .title,
    .name,
    .line,
    .descr {
      display: none;
    }

    :global(.ant-menu-item-group) {
      position: relative;
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        position: absolute;
        left: 0;
        top: 50%;
        background: $border;
      }
    }

    :global(.ant-menu-item-group-title) {
      visibility: hidden;
      transition: none !important;
    }

    :global(.ant-menu-inline-collapsed-tooltip) .icon {
      display: none;
    }

    :global(.ant-menu-item),
    :global(.ant-menu-submenu-title) {
      padding-left: 0 !important;
      padding-right: 0 !important;

      .icon {
        right: 14px;
      }
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////
  /* BANNER COMPONENT */
  .banner {
    padding: rem(20);
    background: $light;
    border-radius: 3px;
    margin: rem(20);
    overflow: hidden;
    border: 1px solid $border;
  }

  :global(.ant-menu-inline-collapsed) + .banner {
    display: none;
  }
}

/////////////////////////////////////////////////////////////////////////////////////////
/* GRAY THEME */
.gray {
  background: $light !important;
}

/////////////////////////////////////////////////////////////////////////////////////////
/* DARK THEME */
.dark {
  color: $dark-gray-2;
  background: $dark-gray-6 !important;

  .icon {
    color: $dark-gray-2;
  }

  .banner {
    background: $dark-gray-4;
    border: 1px solid $dark-gray-4;
  }

  :global(.ant-layout-sider-trigger) {
    background: lighten($dark-gray-6, 5%);
  }

  .navigation {
    :global(.ant-menu-item-group-title) {
      color: darken($dark-gray-3, 10%) !important;
    }

    :global(.ant-menu-submenu-title) {
      color: $dark-gray-2;

      &:hover {
        color: $primary;
      }
    }

    :global(.ant-menu-item) {
      > span {
        color: $dark-gray-2;
      }
      > a {
        color: $dark-gray-2;

        &:hover {
          color: $primary;

          .icon {
            color: $primary;
          }
        }
      }
    }

    :global(.ant-menu-submenu-open),
    :global(.ant-menu-submenu-active),
    :global(.ant-menu-item-active) {
      background: $dark-gray-4 !important;
    }

    :global(.ant-menu-item-active) {
      > span {
        color: $primary;
      }
      &:global(.ant-menu-item-selected) {
        background: $primary !important;
      }
    }

    :global(.ant-menu-item-disabled) {
      > span {
        color: $dark-gray-3;
      }
    }
  }

  &:global(.ant-layout-sider-collapsed) {
    :global(.ant-menu-item-group) {
      &:after {
        background: $dark-gray-4;
      }
    }
  }
}
