.appBox {
  position: relative;
  min-height: 100vh;
  flex-direction: row;
}

.pageBox {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  width: calc(100vw - 256px);

  &__tablet {
    width: 100vw;
  }
}