@import 'src/components/mixins.scss';

.menuContainer {
  background: $white !important;
  z-index: 999;
  position: fixed;
  height: 100%;
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transform: translateX(-100%);

  &__visible {
    transform: translateX(0);
  }

  body.ant-scrolling-effect & {
    z-index: 999;
  }
}