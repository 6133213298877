@import 'src/components/kit/vendors/antd/style';
@import 'src/components/kit/vendors/nprogress/style';

// Clean UI KIT styles
@import 'src/components/kit/core/css/core'; 
@import 'src/components/kit/core/css/measurements'; 
@import 'src/components/kit/core/css/colors';

html{
  font-size: 14px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
}
body{
  background-color: #f2f4f8;
  caret-color: transparent;
}
input,
textarea,
[contenteditable="true"] {
  caret-color: initial;
}

.ant-notification {
  @media (max-width: $sm-max-width) {
    z-index: 1061 !important;
  }
}