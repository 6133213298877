@import 'components/mixins.scss';

.header {
  width: 100%;
  background: $white !important;
  border-bottom: 1px solid #e4e9f0;
  display: flex;
  justify-content: flex-end;

  &__tablet {
    z-index: 1060;
    justify-content: space-between;
  }
}

.headerControls {
  display: flex;
  padding-right: 0.5rem;
  gap: 1rem;
}